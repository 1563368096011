// Home.js
import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import resumeData from "../data/resumeData"

const Home = () => {
  return (

    <Container fluid className="min-vh-100" style={{ justifyContent: "center" }}>
      <Row style={{ justifyContent: "center" }}>
        <Col>
          <Container fluid className={"home"}>
            <Row>
              <Col>
                <div style={{ margin: "20px", width: "100%" }}>
                  <h1>Hossam Katory</h1>
                  <h4>Solutions Architect <br />Software Engineer</h4>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 2 }}>
                <div align="right" style={{ margin: "20px" }}>
                  <img width={"100%"} src={require('../static/images/DSC0579_crop.jpg')} alt=""></img>
                </div>
              </Col>
              <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }}>
                <div style={{ margin: "20px" }}>
                  <div dangerouslySetInnerHTML={{ __html: resumeData.homeIntro }}></div>
                </div>
              </Col>
            </Row>
          </Container >
        </Col>
      </Row>
    </Container>
  );
};

export default Home;